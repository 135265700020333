<template>
  <div class="btn-group hiddenclass" v-if="device==='desktop'">
    <button class="btn btn-outline-primary dropdown-toggle" type="button" :class="{'disabled' : filterDisabled}" ref="budgetFilter"
     id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" @click="() => showedDropdown()">
      {{(isFiltered) ? $t("sabre.search-panel.filtered.up-to-per-person", {value: duration[1]}) : $t("sabre.search-result.budget")}}
    </button>
    <div class="dropdown-menu dropdownranger" aria-labelledby="dropdownMenuClickableInside">

      <range-slider v-model="duration" type="budget" :min="minBudget" :max="maxBudget" :step="budgetStep"/>

      <div class="btn-group-area d-flex justify-content-center">
        <button class="cleanup_btn" @click="() => clean()">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>
  </div>

  <div class="accordion-item" v-else>
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" :class="{'disabled' : filterDisabled}"
       data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
        {{$t("sabre.search-result.budget")}}
      </button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">

        <range-slider v-model="duration" type="budget" :min="minBudget" :max="maxBudget" :step="budgetStep"/>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import rangeSlider from '@/sabre/common/atom/rangeSlider';
import filterControlMixin from '@/sabre/fo/searchResult/filterPanel/filterControlMixin';

export default {
  mixins: [filterControlMixin],
  props: ['type'],
  components: { rangeSlider },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    maxBudget() {
      const { filterConditions } = this;
      return filterConditions?.maxBudget || 10000;
    },
    minBudget() {
      const { filterConditions } = this;
      return filterConditions?.minBudget || 0;
    },
    budgetStep() {
      const { filterConditions } = this;
      return filterConditions?.budgetStep || 10;
    },
    isFiltered() {
      return this.filterApplied && (this.duration[0] !== this.minBudget || this.duration[1] !== this.maxBudget);
    },
  },
  watch: {
    duration() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'budgetDuration', value: this.duration });
    },
    budgetDuration() {
      this.duration = this.budgetDuration;
    },
  },
  data() {
    return {
      duration: [0, 10000],
      filterApplied: false,
    };
  },
  methods: {
    clean() {
      this.duration = [this.minBudget, this.maxBudget];
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'budgetDuration', value: this.duration });
    },
    save() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.budgetFilter).hide();
      this.applyFilter();
      this.filterApplied = true;
    },
    showedDropdown() {
      this.filterApplied = false;
    },
  },
};
</script>
